import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "next-js"
    }}>{`Next JS`}</h1>
    <h2 {...{
      "id": "add-new-site"
    }}>{`Add new site`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to your Laravel Forge Server and add a new site.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Root domain: the root domain of the new site.`}<br />{`Make sure the domain exists and a `}<inlineCode parentName="li">{`A`}</inlineCode>{` record has been added to point to the server IP.`}</li>
          <li parentName="ul">{`Project type: `}<inlineCode parentName="li">{`Static HTML`}</inlineCode><br /></li>
          <li parentName="ul">{`Web Directory: `}<inlineCode parentName="li">{`/`}</inlineCode><br /></li>
          <li parentName="ul">{`PHP Version: `}<inlineCode parentName="li">{`7.3`}</inlineCode>
            <img alt="Step 1" src={require("./LaravelForge/forge-11.png")} /></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open the new site in Forge and connect it to your Github repository, where the source code is hosted.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Installation Options: `}<inlineCode parentName="li">{`false`}</inlineCode></li>
          <li parentName="ul">{`Deploy Key: `}<inlineCode parentName="li">{`false`}</inlineCode>
            <img alt="Step 2" src={require("./LaravelForge/forge-12.png")} /></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go the SSL tab in your site and generate a new `}<inlineCode parentName="p">{`Let's Encrypt`}</inlineCode>{` certificate.
`}<img alt="Step 3" src={require("./LaravelForge/forge-13.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "setup-nginx"
    }}>{`Setup Nginx`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to your site in Forge and scroll down to the bottom to edit the `}<inlineCode parentName="p">{`Nginx Configuration`}</inlineCode>{`.
`}<img alt="Step 4" src={require("./LaravelForge/forge-10.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`On top of the file between the default config import and the `}<inlineCode parentName="p">{`server`}</inlineCode>{` directive, add a new block to create a upstream group for the node process.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Replace `}<inlineCode parentName="li">{`YOUR-PORT`}</inlineCode>{` with a random port, but make sure its unused! It is usually recommended to start with port `}<inlineCode parentName="li">{`3000`}</inlineCode>{` and increase it from there with every new instance. Remember the value, because we will reuse it later.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-nginx"
          }}>{`upstream nodejs_YOUR-PORT_upstream {
    server 127.0.0.1:YOUR-PORT;
    keepalive 64;
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Scroll down into the `}<inlineCode parentName="p">{`server`}</inlineCode>{` directive and replace the `}<inlineCode parentName="p">{`location /`}</inlineCode>{` directive.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Replace `}<inlineCode parentName="li">{`YOUR-PORT`}</inlineCode>{` with the port you used in step 2.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-nginx"
          }}>{` location / {
   proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
   proxy_set_header X-Real-IP $remote_addr;
   proxy_set_header Host $http_host;
   proxy_http_version 1.1;
   proxy_set_header Upgrade $http_upgrade;
   proxy_set_header Connection "upgrade";
   proxy_pass http://nodejs_YOUR-PORT_upstream/;
   proxy_redirect off;
   proxy_read_timeout 240s;
 }
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save the file.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "npm-private-registry"
    }}>{`NPM Private Registry`}</h2>
    <p>{`If the website has some private `}<inlineCode parentName="p">{`@madebywild/*`}</inlineCode>{` dependencies, before deploying the site be sure to launch this command from the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm login --registry=https://registry.wild.plus
`}</code></pre>
    <p>{`Follow the wizard using `}<inlineCode parentName="p">{`NPM_USER`}</inlineCode>{`, `}<inlineCode parentName="p">{`NPM_PWD`}</inlineCode>{` and `}<inlineCode parentName="p">{`NPM_EMAIL`}</inlineCode>{` from `}<a parentName="p" {...{
        "href": "https://wild-studio.1password.eu/vaults/ag5b47wl4fh4rfcu5tfmwacrwq/allitems/qrxofwdjhc6bahwil7ylg3oovm"
      }}>{`1PWD`}</a>{`.`}<br parentName="p"></br>{`
`}{`This command will append a new line to the global `}<inlineCode parentName="p">{`~/.npmrc`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`//registry.wild.plus/:_authToken={TOKEN}
`}</code></pre>
    <h2 {...{
      "id": "install-nvm-and-node"
    }}>{`Install NVM and Node`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Connect to the server`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Launch these commands:`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -o- https://raw.githubusercontent.com/nvm-sh/nvm/v0.39.1/install.sh | bash
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nvm install 14.16.1
`}</code></pre>
    <h2 {...{
      "id": "deploy-site"
    }}>{`Deploy site`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go back to the `}<inlineCode parentName="p">{`Apps`}</inlineCode>{` tab in your site and update the `}<inlineCode parentName="p">{`Deploy Script`}</inlineCode>{`. Remember to replace the variables with actual values.`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`YOUR-SITE-DOMAIN`}</inlineCode>{`: site domain`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`YOUR-SITE-NAME`}</inlineCode>{`: site name - can be any string`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`YOUR-PORT`}</inlineCode>{`: the node port you chose when setting up `}<a parentName="li" {...{
              "href": "#setup-nginx"
            }}>{`Nginx`}</a></li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`cd /home/forge/YOUR-SITE-DOMAIN

# Cleanup local repo and pull updates
git checkout .
git clean -fd
git pull origin $FORGE_SITE_BRANCH

# Switch to Node LTS
. ~/.nvm/nvm.sh
nvm use 14.16.1

# Install new deps, but avoid updating the lock file
npm ci

# Build the application
npm run build

# Start pm2 instance (if running, kill and restart it)
pm2 delete -s YOUR-SITE-NAME || :

# Start the application
NODE_ENV=production PORT=YOUR-PORT pm2 start --name="YOUR-SITE-NAME" --interpreter=/home/forge/.nvm/versions/node/v14.16.1/bin/node npm -- start -- -p YOUR-PORT
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Optionally, you can now switch to the `}<inlineCode parentName="p">{`Environment`}</inlineCode>{` tab in your Forge site and add your ENV variables.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`At this point you can click `}<inlineCode parentName="p">{`Deploy now`}</inlineCode>{` and optionally enable Automatic deployments.`}</p>
      </li>
    </ol>
    <h3 {...{
      "id": "pm2"
    }}>{`pm2`}</h3>
    <p>{`As you might have noticed from the deploy script, we use `}<a parentName="p" {...{
        "href": "https://github.com/Unitech/pm2"
      }}><inlineCode parentName="a">{`pm2`}</inlineCode></a>{` to manage the node process that runs the Next.Js server. Here are a couple of useful commands:`}</p>
    <h4 {...{
      "id": "restarting"
    }}>{`Restarting`}</h4>
    <p>{`In order to automatically restart the node processes on a server in case of a reboot, we have to save the running processes once and then add it to the Scheduler of the server.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the server in Laravel Forge, click on the `}<inlineCode parentName="p">{`Scheduler`}</inlineCode>{` Tab and enter the command `}<inlineCode parentName="p">{`/usr/bin/pm2 resurrect`}</inlineCode>{` and select `}<inlineCode parentName="p">{`On Reboot`}</inlineCode>{` for the frequency.
`}<img alt="Add pm2 resurrect to scheduler" src={require("./LaravelForge/forge-14.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Connect to the server, check the running pm2 processes with `}<inlineCode parentName="p">{`pm2 list`}</inlineCode>{` and check if all processes are running as expected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run `}<inlineCode parentName="p">{`pm2 save`}</inlineCode>{` once. This will save all running processes and in case of a server reboot, those would be restarted automatically.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "commands"
    }}>{`Commands`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pm2 list       # List all running pm2 processes.
pm2 logs       # Show logs of pm2 and node processes.
pm2 flush      # Delete all logs.
`}</code></pre>
    <p>{`For a full list of options visit the `}<a parentName="p" {...{
        "href": "https://github.com/Unitech/pm2"
      }}>{`pm2 documentation`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      